import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/tailwind/tailwind.min.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={`bg-body text-body font-body ${bodyClass ? bodyClass : ``}`} />
            </Helmet>

            <div className="container mx-auto px-4">

                {/* The main header section on top of the screen 
                    <header className="site-head" style={{ ...site.cover_image && { backgroundImage: `url(${site.cover_image})` } }}>
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left">
                                    <Link to="/">
                                        {site.logo ?
                                            <img className="site-logo" src={site.logo} alt={site.title} />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                        }
                                    </Link>
                                </div>
                                <div className="site-mast-right">
                                    { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
                                    { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" /></a>}
                                    <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="RSS Feed" /></a>
                                </div>
                            </div>
                            { isHome ?
                                <div className="site-banner">
                                    <h1 className="site-banner-title">{site.title}</h1>
                                    <p className="site-banner-desc">{site.description}</p>
                                </div> :
                                null}
                            <nav className="site-nav">
                            <div className="site-nav-left">*/}
                {/* The navigation items as setup in Ghost 
                                    <Navigation data={site.navigation} navClass="site-nav-item" />
                                </div>
                                <div className="site-nav-right">
                                    <Link className="site-nav-button" to="/about">About</Link>
                                </div>
                            </nav>
                        </div>
                    </header>*/}

                <nav>
                    <div className="flex flex-wrap items-center justify-between p-4 border-t">
                        <div className="flex flex-shrink-0 mr-6"><a className="text-xl text-indigo-500 font-semibold"
                            href="/">ProductEssentials</a></div>
                        <div className="block lg:hidden"><button
                            className="flex items-center py-2 px-3 text-indigo-500 rounded border border-indigo-500"><svg
                                className="fill-current h-3 w-3" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg></button></div>
                        <div className="hidden lg:flex lg:flex-grow lg:items-center w-full lg:w-auto">
                            <div className="lg:ml-auto"><a
                                className="block lg:inline-block mt-4 lg:mt-0 mr-12 text-blue-900 hover:text-blue-700"
                                href="https://apps.shopify.com/discount-app?referrer=pe"><span>Disco</span><span className="block text-sm text-gray-500">Premium Shopify Discount
                                App</span></a><a
                                className="block lg:inline-block mt-4 lg:mt-0 mr-12 text-blue-900 hover:text-blue-700" href="#">
                                <span>Articles</span><span className="block text-sm text-gray-500">Ecommerce Advice and
                                Insights</span></a><a
                                className="block lg:inline-block mt-4 lg:mt-0 mr-12 text-blue-900 hover:text-blue-700" href="#">
                                <span>Support</span><span className="block text-sm text-gray-500">Need help?</span></a></div>
                            <div><a
                                className="inline-block py-3 px-6 mt-4 lg:mt-0 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow"
                                href="https://apps.shopify.com/discount-app?referrer=pe">Try Disco</a></div>
                        </div>
                    </div>
                </nav>

                <main className="site-main">
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>

                {/* The footer at the very bottom of the screen *
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <Link to="/">{site.title}</Link> © 2019 &mdash; Published with <a className="site-foot-nav-item" href="https://ghost.org" target="_blank" rel="noopener noreferrer">Ghost</a>
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>*/}
                <footer className="flex flex-wrap flex-col lg:flex-row items-center p-4">
                    <div className="flex flex-shrink-0"><a className="text-xl text-indigo-500 font-semibold"
                        href="#">ProductEssentials</a></div>
                    <div className="mt-4 lg:mt-0 lg:mx-auto"><a
                        className="inline-block mt-0 mb-4 lg:mb-0 mr-8 text-blue-900 hover:text-blue-700" href="#">Articles</a><a
                        className="inline-block mt-0 mb-4 lg:mb-0 mr-8 text-blue-900 hover:text-blue-700" href="#">Support</a><a
                        className="inline-block mt-0 mb-4 lg:mb-0 mr-8 text-blue-900 hover:text-blue-700" href="#">Customers</a><a
                        className="inline-block mt-0 mb-4 lg:mb-0 text-blue-900 hover:text-blue-700" href="#">Blog</a></div>
                    <div>&copy; 2019 ProductEssentials</div>
                </footer>

            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
